.content__container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
}
.content {
  background-color: var(--background-color);
  margin-top: 30px;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  color: var(--primary-color);
}
