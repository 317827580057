.profile {
  display: flex;
}

.profile__initials {
  height: 40px;
  width: 40px;
  background-color: var(--background-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border-radius: 20px;
}
