.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6;
  overflow: auto;
}
.noDisplay {
  display: none;
}
