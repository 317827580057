.bankDetails__accountInfo {
  background-color: #e8f2e3;
  padding: 5px 15px;
  border-radius: 5px;
}
.bankDetails__accountTitle {
  color: #174542;
  font-size: 11px;
  margin-bottom: 0;
  font-weight: 300;
}
.bankDetails__accountValue {
  color: #174542;
  font-size: 13px;
  font-weight: bold;
}
