.card {
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 13px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  max-width: 480px;
  background-color: #fff;
  margin: 50px auto;
  padding: 50px 40px;
  position: relative;

  /* z-index: 2; */
}

.card__waveContainer {
  position: absolute;
  left: -80px;
  top: 20%;
  z-index: -1;
  /* max-width: 100%; */
  overflow: hidden;
}

.card__title {
  color: #434159;
  margin-bottom: 30px;
}
