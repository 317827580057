.amountModal {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 6;
  width: 80%;
}
.amountModal__closeBtn {
  float: right;
  cursor: pointer;
}
