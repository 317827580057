.viaBank {
  padding: 0 60px;
  flex-grow: 1;
}
.viaBank__title {
  /* color: #8773b9; */
  margin-bottom: 30px;
}
.viaBank input {
  border: none;
}
/* .viaBank input::placeholder {
  color: #9f8ec7;
} */
