.navbar {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.navbar__logo {
  width: 130px;
}

.navbar__initials {
  height: 40px;
  width: 40px;
  background-color: var(--background-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border-radius: 20px;
}

.navbar__username {
  font-weight: bold;
  margin: 0;
  color: #3f536e;
}

.navbar__rightContent {
  /* width: 180px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
}

.navbar__chevron {
  color: #3f536e;
  font-size: 11px;
}
