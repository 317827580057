.section__item {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  margin-bottom: 15px;
}
.section__item i {
  margin-left: 10px;
  font-size: 18px;
  visibility: hidden;
}

.section__itemActive {
  font-weight: bold;
}
.section__itemActive i {
  visibility: visible;
}
.vl {
  width: 1px;
  background-color: var(--primary-color);
  margin-left: 30px;
  opacity: 0.5;
}
